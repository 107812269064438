import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BusanteiFooterModule } from '../busantei-footer/busantei-footer.module';
import { BusanteiHeaderModule } from '../busantei-header/busantei-header.module';
import { BusanteiLayoutComponent } from './busantei-layout.component';

@NgModule({
  declarations: [BusanteiLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    BusanteiHeaderModule,
    BusanteiFooterModule,
    FlexLayoutModule,
    MatMenuModule,
  ],
})
export class BusanteiLayoutModule {}
