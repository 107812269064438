import { AnimationEvent } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PwLayoutService } from 'pw-lib';
import { Subscription } from 'rxjs';
import { slideAnimation } from 'src/app/core/animation';

/**
 * 라우팅 이력 및 애니메이션 관리 컴포넌트. 앱 구현시에만 사용할것. 웹 혼용시 사용금지.
 */
@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  animations: [slideAnimation],
})
export class PageWrapperComponent implements OnInit, OnDestroy {
  /**
   * 애니메이션 동작 여부
   */
  @Input() shouldAnimate = true;

  /**
   * 현재 위치 Root라면 0
   */
  depth: number;

  /**
   * 현재 페이지의 Root로부터의 라우팅 경로
   */
  private pathFromRoot: string;

  /**
   * `Router.event` 구독
   */
  private routerSubscription: Subscription;

  /**
   * `display: none` 여부. 이 페이지가 현재 페이지이거나, 애니메이션중이면서 현재의 하나 아래 페이지일때.
   */
  get shouldDisplay(): boolean {
    return this.depth === this.pwLayoutService.currentDepth || this.canAnimate;
  }

  /**
   * 애니메이션 동작 가능 여부. 자식 페이지가 현재 보고있는 페이지일때.
   */
  get canAnimate(): boolean {
    return this.depth === this.pwLayoutService.animatingDepth - 1;
  }

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public pwLayoutService: PwLayoutService
  ) {
    // 현재 depth와 list의 인덱스를 일치시키기 위해 length 먼저 저장
    this.depth = this.pwLayoutService.pathList.length;
    this.pathFromRoot = this.pwLayoutService.getPathFromRoot(activatedRoute);
    this.pwLayoutService.pathList.push(this.pathFromRoot);
    this.pwLayoutService.animationDirection$.next('toLeft');

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      if (this.depth !== this.pwLayoutService.currentDepth) {
        return;
      }

      // depth가 유지된채로 path만 바뀌는 경우 저장된 이력도 수정
      // eslint-disable-next-line no-restricted-globals
      if (location.pathname !== this.pwLayoutService.pathList[this.depth]) {
        this.pathFromRoot = this.pwLayoutService.getPathFromRoot(
          this.activatedRoute
        );
        this.pwLayoutService.pathList[this.depth] = this.pathFromRoot;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.pwLayoutService.animationDirection$.next('toRight');
    this.pwLayoutService.pathList.pop();
    this.routerSubscription.unsubscribe();
  }

  /**
   * 라우팅 애니메이션 종료시 동작
   */
  onAnimationsDone(e: AnimationEvent): void {
    if (e.toState) {
      this.pwLayoutService.animationDirection$.next(null);
    }
  }
}
