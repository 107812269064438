import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BREAKPOINT,
  BreakPoint,
  FlexLayoutModule,
  LayoutConfigOptions,
  LAYOUT_CONFIG,
} from '@angular/flex-layout';
import { OrientationClassDirective } from './extended/class/class.orientation';
import { OrientationImgSrcDirective } from './extended/img-src/img-src.orientation';
import { OrientationShowHideDirective } from './extended/show-hide/show-hide.orientation';
import { OrientationStyleDirective } from './extended/style/style.orientation';
import { OrientationFlexDirective } from './flex/flex/flex.orientation';
import { OrientationLayoutAlignDirective } from './flex/layout-align/layout-align.orientation';
import { OrientationLayoutGapDirective } from './flex/layout-gap/layout-gap.orientation';
import { OrientationLayoutDirective } from './flex/layout/layout.orientation';
import { OrientationGridColumnsDirective } from './grid/columns/columns.orientation';
import { OrientationGridGapDirective } from './grid/gap/gap.orientation';
import { OrientationGridRowsDirective } from './grid/rows/rows.orientation';

const directives = [
  OrientationLayoutDirective,
  OrientationLayoutGapDirective,
  OrientationClassDirective,
  OrientationStyleDirective,
  OrientationFlexDirective,
  OrientationGridColumnsDirective,
  OrientationGridRowsDirective,
  OrientationGridGapDirective,
  OrientationShowHideDirective,
  OrientationLayoutAlignDirective,
  OrientationImgSrcDirective,
];

@NgModule({
  declarations: directives,
  imports: [CommonModule, FlexLayoutModule],
  providers: [
    {
      provide: LAYOUT_CONFIG,
      useValue: <LayoutConfigOptions>{ addOrientationBps: true },
    },
    {
      provide: BREAKPOINT,
      useValue: <BreakPoint[]>[
        {
          alias: 'landscape',
          priority: 1000,
          mediaQuery: '(orientation: landscape)',
        },
        {
          alias: 'portrait',
          priority: 1000,
          mediaQuery: '(orientation: portrait)',
        },
      ],
      multi: true,
    },
  ],
  exports: directives,
})
export class FlexLayoutOrientationModule {}
