import { Directive } from '@angular/core';
import { ImgSrcDirective } from '@angular/flex-layout';

const inputs = [
  'src.landscape',
  'src.portrait',

  'src.handset',
  'src.handset.landscape',
  'src.handset.portrait',

  'src.tablet',
  'src.tablet.landscape',
  'src.tablet.portrait',

  'src.web',
  'src.web.landscape',
  'src.web.portrait',
];

const selector = `img[src.landscape], img[src.portrait],
img[src.handset], img[src.handset.landscape], img[src.handset.portrait],
img[src.tablet], img[src.tablet.landscape], img[src.tablet.portrait],
img[src.web], img[src.web.landscape], img[src.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationImgSrcDirective extends ImgSrcDirective {
  protected override inputs = inputs;
}
