<app-busantei-header [hasBack]="false">
  <img
    src="/assets/img/busantei_logo.png"
    src.lt-sm="/assets/img/busantei_logo_s.png"
  />

  <div class="language-wrapper">
    <img
      class="language-button"
      src="/assets/img/language.png"
      [mat-menu-trigger-for]="language"
    />
  </div>

  <mat-menu #language="matMenu">
    <button mat-menu-item (click)="onLanguageChange('ja')">
      <span>日本語</span>
    </button>
    <button mat-menu-item (click)="onLanguageChange('ko')">
      <span>한국어</span>
    </button>
  </mat-menu>
</app-busantei-header>

<router-outlet></router-outlet>

<app-busantei-footer></app-busantei-footer>
