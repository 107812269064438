import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-busantei-layout',
  templateUrl: './busantei-layout.component.html',
  styleUrls: ['./busantei-layout.component.scss'],
})
export class BusanteiLayoutComponent implements OnInit {
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {}

  onLanguageChange(lang: string): void {
    this.languageService.changeLang(lang);
  }
}
