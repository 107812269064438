import { Directive } from '@angular/core';
import { ShowHideDirective } from '@angular/flex-layout';

const inputs = [
  'fxShow.landscape',
  'fxShow.portrait',

  'fxHide.landscape',
  'fxHide.portrait',

  'fxShow.handset',
  'fxShow.handset.landscape',
  'fxShow.handset.portrait',

  'fxHide.handset',
  'fxHide.handset.landscape',
  'fxHide.handset.portrait',

  'fxShow.tablet',
  'fxShow.tablet.landscape',
  'fxShow.tablet.portrait',

  'fxHide.tablet',
  'fxHide.tablet.landscape',
  'fxHide.tablet.portrait',

  'fxShow.web',
  'fxShow.web.landscape',
  'fxShow.web.portrait',

  'fxHide.web',
  'fxHide.web.landscape',
  'fxHide.web.portrait',
];

const selector = `[fxShow.landscape], [fxShow.portrait],
[fxHide.landscape], [fxHide.portrait],
[fxShow.handset], [fxShow.handset.landscape], [fxShow.handset.portrait],
[fxHide.handset], [fxHide.handset.landscape], [fxHide.handset.portrait],
[fxShow.tablet], [fxShow.tablet.landscape], [fxShow.tablet.portrait],
[fxHide.tablet], [fxHide.tablet.landscape], [fxHide.tablet.portrait],
[fxShow.web], [fxShow.web.landscape], [fxShow.web.portrait],
[fxHide.web], [fxHide.web.landscape], [fxHide.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationShowHideDirective extends ShowHideDirective {
  protected override inputs = inputs;
}
