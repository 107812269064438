import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusanteiLayoutComponent } from './components/busantei-layout/busantei-layout.component';
import { AppUpdateGuard } from './core/app-update.guard';

const routes: Routes = [
  {
    path: '',
    component: BusanteiLayoutComponent,
    canActivate: [AppUpdateGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'qna',
        loadChildren: () =>
          import('./pages/qna/qna.module').then((m) => m.QnaModule),
      },
    ],
  },

  // // 뒤로가기
  // { path: 'back', canActivate: [RouteGuard], component: MainLayoutComponent },
  // // 상태 유지하며 홈으로 이동
  // { path: 'root', canActivate: [RouteGuard], component: MainLayoutComponent },
  // // 상태 초기화하며 홈으로 이동
  // {
  //   path: 'root/reset',
  //   canActivate: [RouteGuard],
  //   component: MainLayoutComponent,
  // },
  // // 로그아웃
  // { path: 'logout', canActivate: [RouteGuard], component: MainLayoutComponent },
  // // 로그아웃, 메시지 표시
  // {
  //   path: 'logout/:msg',
  //   canActivate: [RouteGuard],
  //   component: MainLayoutComponent,
  // },
  // // 페이지 상태 초기화
  // { path: 'reset', component: ResetComponent },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: () => {
        if (window.outerWidth < 600) {
          return [0, 56];
        }
        return [0, 210];
      },
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
