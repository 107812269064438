import { AnimationEvent } from '@angular/animations';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { UiService } from './ui.service';

type PageType = undefined | 'root' | 'tab';

/**
 * 이력관리, 애니메이션 처리 등 라우팅 보조 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class RouteHelperService {
  currentPath: string;

  /**
   * 지금 실행중인 라우팅이 뒤로가기인지 확인하기 위한 변수
   * CustomRouteReuseStrategy 및 기타 전역에서 사용하기 위해 static 선언
   * @deprecated
   */
  static isGoingBack = false;

  /**
   * 라우팅 애니메이션 방향
   */
  routeDirection$ = new BehaviorSubject<'forward' | 'back'>(null);

  /**
   * 후방향 라우팅 스크롤 복원용 변수
   * @deprecated
   */
  scrollTo: ScrollToOptions = {};

  /**
   * 후방향 라우팅 스크롤 복원용 내부 변수
   * @deprecated
   */
  private _scrollPosition: ScrollToOptions = {};

  /**
   * 현재 홈 경로
   * @deprecated
   */
  private _currentRoot = '/';

  /**
   * 라우팅 이력, 리스트를 스택처럼 사용함
   * @deprecated
   */
  private _routeHistoryList: [string, ScrollToOptions][] = [
    [this._currentRoot, {}],
  ];

  /**
   * 라우팅 이력 관리를 위한 현재 페이지 타입, `undefined`인 경우 일반 페이지
   */
  private _pageType: PageType;

  constructor(
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _uiSerivce: UiService
  ) {
    this.setRouteEvent();
    // this._setScrollEvent();
  }

  /**
   * 이전 화면으로 이동
   * @deprecated
   */
  goBack(): void {
    RouteHelperService.isGoingBack = true;

    //
    const pageToBack = this._routeHistoryList.length < 2 ? 1 : 2;

    // 복원할 스크롤 위치 저장
    [, this.scrollTo] =
      this._routeHistoryList[this._routeHistoryList.length - 2];

    // 이전 페이지로 이동
    this.router.navigateByUrl(
      this._routeHistoryList[this._routeHistoryList.length - pageToBack][0]
    );
  }

  /**
   * ActivatedRoute의 마지막 child를 반환
   */
  getCurrentActivatedRoute(): ActivatedRoute {
    let route = this._activatedRoute;

    if (!route) {
      throw new Error('ActivatedRoute 없음');
    }

    // firstChild가 없을때까지 탐색
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  /**
   * @deprecated
   */
  goWithClear(url): void {
    this._routeHistoryList = [];
    this.router.navigate([url]);
  }

  /**
   * 라우팅 애니메이션 시작시
   */
  animationsStart(e: AnimationEvent): void {
    // if (e.toState) {
    // }
  }

  /**
   * 라우팅 애니메이션 종료시
   */
  animationsDone(e: AnimationEvent): void {
    if (e.toState) {
      this.routeDirection$.next(null);
    }
  }

  /**
   * 라우팅시 라우팅 이력 기록, 라우팅 애니메이션 제어 등 이벤트 등록
   */
  private setRouteEvent(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.routeDirection$.next('forward');
          // 현재 경로 저장
          this.currentPath = event.url;

          // 이동할 페이지의 타입
          const { type } = this.getCurrentActivatedRoute().snapshot.data;

          // 페이지 전환 애니메이션 실행
          this._animate(type);

          // 라우팅 이력 처리
          this._setRouteHistory(event.url, type);

          // 페이지 타입 기록
          this._pageType = type;

          RouteHelperService.isGoingBack = false;
        })
      )
      .subscribe();
  }

  /**
   * @deprecated
   */
  private _animate(type: PageType): void {
    // 루트 > 루트면 전환 애니메이션 없음
    if (this._pageType === 'root' && type === 'root') {
      return;
    }

    // 라우팅 방향에 따라 페이지 전환 방향 결정
    const direction = RouteHelperService.isGoingBack ? 'back' : 'forward';
    this.routeDirection$.next(direction);
  }

  /**
   * @deprecated
   */
  private _setRouteHistory(url: string, type: PageType): void {
    // ? > 루트
    if (type === 'root') {
      // 현재 루트 변경 밑 이력 비우기
      this._currentRoot = url;
      this._routeHistoryList = [[url, {}]];
      return;
    }

    // 뒤로가기
    if (RouteHelperService.isGoingBack) {
      // 마지막 이력 삭제
      this._routeHistoryList.pop();
      return;
    }

    // 탭 > 탭
    if (this._pageType === 'tab' && type === 'tab') {
      // 마지막 이력 삭제
      this._routeHistoryList.pop();
    }

    // 페이지 추가
    this._routeHistoryList.push([url, {}]);

    // 이전페이지 스크롤 위치 갱신
    this._routeHistoryList[this._routeHistoryList.length - 2][1] =
      this._scrollPosition;
  }

  /**
   * 후방향 라우팅시 스크롤 복원을 위해 스크롤 이벤트 구독
   * @deprecated
   */
  private _setScrollEvent(): void {
    this._uiSerivce.scrollEvent$.subscribe(({ scrollTop, scrollLeft }) => {
      this._scrollPosition = { top: scrollTop, left: scrollLeft };
    });
  }
}
