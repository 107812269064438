import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitArray',
})
export class SplitArrayPipe implements PipeTransform {
  transform(
    value: any[],
    ...args: [count: number, fillArray?: boolean]
  ): any[] {
    const array = [];
    for (let i = 0; i < value?.length; i += args[0]) {
      const child = value.slice(i, i + args[0]);
      if (child.length !== args[0] && args[1]) {
        for (let j = child.length - 1; j < args[0] - 1; j += 1) {
          child.push(null);
        }
      }
      array.push(child);
    }
    return array;
  }
}
