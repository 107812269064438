import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService, BridgeService } from 'projects/pw-lib/src/public-api';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertComponent } from '../components/dialog/alert/alert.component';
import { Utils } from './utils';

/**
 * 플러터 웹앱 업데이트 확인
 * 업데이트 있으면 접근 제한, Dialog 표시 후 각 플랫폼 스토어로 이동
 */
@Injectable({
  providedIn: 'root',
})
export class AppUpdateGuard implements CanActivate {
  constructor(
    private bridgeService: BridgeService,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private translateService: TranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // 앱이 아니라면 허용
    if (!Utils.isApp()) {
      return true;
    }

    const { platform } = this.bridgeService;
    // 앱이 아니라면 허용
    if (platform === 'WEB') {
      return true;
    }
    // 플러터 앱 버전 조회
    return from(this.appService.version).pipe(
      map((version) => {
        // 버전 정보 없으면 일반 웹
        if (!version) {
          return true;
        }
        const major = Number.parseInt(version.split('.')[0], 10);
        const minor = Number.parseInt(version.split('.')[1], 10);
        const webAppVersion = environment.webAppVersion[platform];
        // 플러터 앱 버전과 환경 변수의 major, minor 버전 합을 비교
        // 환경 변수의 버전이 높다면 Dialog 표시
        if (major + minor < webAppVersion.major + webAppVersion.minor) {
          // 업데이트 Dialog 가 아직 열리지 않았다면 표시
          if (!this.matDialog.getDialogById('updateRequired')) {
            const updateDialogRef = this.matDialog.open(AlertComponent, {
              data: {
                body: this.translateService.instant('MSG.appUpdateRequired'),
              },
              id: 'updateRequired',
              disableClose: true,
            });
            // Dialog 를 닫을 수 없도록 onYes 재정의
            updateDialogRef.componentInstance.onYes = () => {
              // Dialog 를 닫지 않고, afterClosed 이벤트만 발생시킨다.
              // eslint-disable-next-line no-underscore-dangle
              (<any>updateDialogRef)._afterClosed.next(true);
            };
            updateDialogRef.afterClosed().subscribe(() => {
              // 각 플랫폼에 해당되는 스토어 링크를 연다.
              let storeHref: string;
              if (platform === 'AOS') {
                storeHref = `market://details?id=${environment.packageName}`;
              }
              if (platform === 'IOS') {
                storeHref = `itms-apps://itunes.apple.com/app/id${environment.appStoreId}`;
              }
              this.document.location.href = storeHref;
            });
          }
          return false;
        }
        return true;
      })
    );
  }
}
