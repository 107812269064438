import { Directive } from '@angular/core';
import { FlexDirective } from '@angular/flex-layout';

const inputs = [
  'fxFlex.landscape',
  'fxFlex.portrait',

  'fxFlex.handset',
  'fxFlex.handset.landscape',
  'fxFlex.handset.portrait',

  'fxFlex.tablet',
  'fxFlex.tablet.landscape',
  'fxFlex.tablet.portrait',

  'fxFlex.web',
  'fxFlex.web.landscape',
  'fxFlex.web.portrait',
];

const selector = `[fxFlex.landscape], [fxFlex.portrait],
[fxFlex.handset], [fxFlex.handset.landscape], [fxFlex.handset.portrait],
[fxFlex.tablet], [fxFlex.tablet.landscape], [fxFlex.tablet.portrait],
[fxFlex.web], [fxFlex.web.landscape], [fxFlex.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationFlexDirective extends FlexDirective {
  protected override inputs = inputs;
}
