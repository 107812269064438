import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import {
  MatDialogConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { LoggingInterceptor, PW_STORAGE_PREFIX } from 'pw-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './components/dialog/dialog.module';
import { InputModule } from './components/input/input.module';
import { PageWrapperModule } from './components/page-wrapper/page-wrapper.module';
import { SharedModule } from './components/shared.module';
import { maskConfig } from './core/mask-config';
import {
  PwUpdateOption,
  PW_UPDATE_OPTION,
} from './core/services/app-update.service';
import { PipesModule } from './pipes/pipes.module';

registerLocaleData(localeKo);
registerLocaleData(localeJa);

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'MSG.askUpdate',
  actionTranslateKey: 'BTN.update',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'ko',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DialogModule,
    PipesModule,
    InputModule,
    PageWrapperModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: () => {
        return new LoggingInterceptor(environment.production);
      },
      multi: true,
    },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: PW_STORAGE_PREFIX, useValue: 'BUSANTEI' },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'JPY' },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{ ...new MatDialogConfig(), autoFocus: false },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
