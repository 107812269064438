<div class="popup">
  <span class="header">{{ data?.header }}</span>

  <div class="body">{{ data?.body }}</div>
</div>

<div class="popUp_btn">
  <div class="yes_btn" (click)="onYes()">확인</div>
  <div class="no_btn" (click)="onNo()">취소</div>
</div>
