<div
  id="layout-view-{{ depth }}"
  class="layout-view"
  [class.under-layer]="!shouldDisplay"
>
  <ng-content></ng-content>
</div>

<div
  id="layout-animation-{{ depth + 1 }}"
  [@routeAnimations]="
    shouldAnimate && canAnimate && pwLayoutService.animationDirection$ | async
  "
  (@routeAnimations.done)="onAnimationsDone($event)"
>
  <router-outlet></router-outlet>
</div>
