import { Directive } from '@angular/core';
import { StyleDirective } from '@angular/flex-layout';

const inputs = [
  'ngStyle.landscape',
  'ngStyle.portrait',

  'ngStyle.handset',
  'ngStyle.handset.landscape',
  'ngStyle.handset.portrait',

  'ngStyle.tablet',
  'ngStyle.tablet.landscape',
  'ngStyle.tablet.portrait',

  'ngStyle.web',
  'ngStyle.web.landscape',
  'ngStyle.web.portrait',
];

const selector = `[ngStyle.landscape], [ngStyle.portrait],
[ngStyle.handset], [ngStyle.handset.landscape], [ngStyle.handset.portrait],
[ngStyle.tablet], [ngStyle.tablet.landscape], [ngStyle.tablet.portrait],
[ngStyle.web], [ngStyle.web.landscape], [ngStyle.web.portrait]`;

@Directive({ selector, inputs })
export class OrientationStyleDirective extends StyleDirective {
  protected override inputs = inputs;
}
