import { AnimationEvent } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'pw-lib';
import { tap } from 'rxjs';
import { fadeAnimation } from './core/animation';
import { AppUpdateService } from './core/services/app-update.service';
import { RouteHelperService } from './core/services/route-helper.service';
import { Utils } from './core/utils';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  /**
   * 하드웨어 백버튼 조작시 앱 종료하는 페이지 목록
   */
  APP_EXIT_PATH_LIST = ['/', '/login'];

  /**
   * 뒤로가기 2번 체크
   */
  shouldExitApp = false;

  /**
   * 라우팅 애니메이션 여부
   */
  shouldRouteAnimate = true;

  constructor(
    public routeHelperService: RouteHelperService,
    private renderer2: Renderer2,
    private activatedRoute: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private appService: AppService,
    private appUpdateService: AppUpdateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.appUpdateService.init();
    this.initApp();
    this.initLanguage();
  }

  onAnimationStart(e: AnimationEvent): void {
    this.routeHelperService.animationsStart(e);
  }

  /**
   * 라우팅 애니메이션 종료시
   */
  onAnimationsDone(e: AnimationEvent): void {
    this.routeHelperService.animationsDone(e);
  }

  /**
   * 앱 화면 전체를 사용할 수 있도록 설정하고, 상태바 아이콘을 밝게 설정한다
   */
  private initApp(): void {
    if (!Utils.isApp()) {
      // 앱 아니면 종료
      return;
    }

    this.renderer2.addClass(document.body, 'mobile-app');

    // SafeArea 활성화
    this.appService.useSafeArea(true);

    // 기본 뒤로가기 처리 비활성화
    this.appService.useDefaultBackHandler(false);

    // 하드웨어 백버튼(안드로이드) 이벤트
    this.appService.addBackPressed(() => {
      if (
        ['/', ...this.APP_EXIT_PATH_LIST].indexOf(
          this.routeHelperService.currentPath
        ) < 0
      ) {
        // 루트가 아니면 뒤로가기
        window.history.back();
        return;
      }

      if (this.shouldExitApp) {
        // 한번 더 뒤로가기 하면 종료
        this.appService.exit();
        return;
      }

      const matSnackBar = this.matSnackBar.open(
        '한번 더 뒤로가기 버튼을 누르면 앱이 종료됩니다.',
        null,
        {
          // 2초간 뒤로가기 체크
          duration: 2000,
        }
      );

      // dismiss 전에 다시 누르면 종료
      matSnackBar.afterOpened().subscribe(() => {
        this.shouldExitApp = true;
      });

      // 이후면 초기화
      matSnackBar.afterDismissed().subscribe(() => {
        this.shouldExitApp = false;
      });
    });
  }

  private initLanguage(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap(({ lang }) => {
          if (this.languageService.lang === lang) {
            return;
          }

          const isSupported = this.languageService.SUPPORTED_LANGUAGES.some(
            ({ value }) => value === lang
          );

          if (!isSupported) {
            return;
          }

          this.languageService.changeLang(lang);
        })
      )
      .subscribe();
  }
}
