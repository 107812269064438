import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { PwLocaleModule } from 'projects/pw-lib/src/public-api';
import { BusanteiFooterModule } from './busantei-footer/busantei-footer.module';
import { BusanteiHeaderModule } from './busantei-header/busantei-header.module';
import { BusanteiLayoutModule } from './busantei-layout/busantei-layout.module';
import { FlexLayoutOrientationModule } from './flex-layout-orientation/flex-layout-orientation.module';

const modules = [
  CommonModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  FlexLayoutOrientationModule,
  MatRippleModule,
  MatIconModule,
  MatButtonModule,
  TranslateModule,
  PwLocaleModule,
  BusanteiLayoutModule,
  BusanteiHeaderModule,
  BusanteiFooterModule,
];

/**
 * 공통 모듈
 */
@NgModule({
  declarations: [],
  imports: modules,
  exports: modules,
})
export class SharedModule {}
