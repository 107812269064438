import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';

const componentList = [AlertComponent, ConfirmComponent];

@NgModule({
  declarations: componentList,
  imports: [CommonModule, MatDialogModule],
  exports: [...componentList, MatDialogModule, MatSnackBarModule],
})
export class DialogModule {}
