import { Directive } from '@angular/core';
import { LayoutAlignDirective } from '@angular/flex-layout';

const inputs = [
  'fxLayoutAlign.landscape',
  'fxLayoutAlign.portrait',

  'fxLayoutAlign.handset',
  'fxLayoutAlign.handset.landscape',
  'fxLayoutAlign.handset.portrait',

  'fxLayoutAlign.tablet',
  'fxLayoutAlign.tablet.landscape',
  'fxLayoutAlign.tablet.portrait',

  'fxLayoutAlign.web',
  'fxLayoutAlign.web.landscape',
  'fxLayoutAlign.web.portrait',
];

const selector = `[fxLayoutAlign.landscape], [fxLayoutAlign.portrait],
[fxLayoutAlign.handset], [fxLayoutAlign.handset.landscape], [fxLayoutAlign.handset.portrait],
[fxLayoutAlign.tablet], [fxLayoutAlign.tablet.landscape], [fxLayoutAlign.tablet.portrait],
[fxLayoutAlign.web], [fxLayoutAlign.web.landscape], [fxLayoutAlign.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationLayoutAlignDirective extends LayoutAlignDirective {
  protected override inputs = inputs;
}
