import { Directive } from '@angular/core';
import { LayoutGapDirective } from '@angular/flex-layout';

const inputs = [
  'fxLayoutGap.landscape',
  'fxLayoutGap.portrait',

  'fxLayoutGap.handset',
  'fxLayoutGap.handset.landscape',
  'fxLayoutGap.handset.portrait',

  'fxLayoutGap.tablet',
  'fxLayoutGap.tablet.landscape',
  'fxLayoutGap.tablet.portrait',

  'fxLayoutGap.web',
  'fxLayoutGap.web.landscape',
  'fxLayoutGap.web.portrait',
];

const selector = `[fxLayoutGap.landscape], [fxLayoutGap.portrait],
[fxLayoutGap.handset], [fxLayoutGap.handset.landscape], [fxLayoutGap.handset.portrait],
[fxLayoutGap.tablet], [fxLayoutGap.tablet.landscape], [fxLayoutGap.tablet.portrait],
[fxLayoutGap.web], [fxLayoutGap.web.landscape], [fxLayoutGap.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationLayoutGapDirective extends LayoutGapDirective {
  protected override inputs = inputs;
}
