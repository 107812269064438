import { Directive } from '@angular/core';
import { GridGapDirective } from '@angular/flex-layout';

const inputs = [
  'gdGap.landscape',
  'gdGap.portrait',

  'gdGap.handset',
  'gdGap.handset.landscape',
  'gdGap.handset.portrait',

  'gdGap.tablet',
  'gdGap.tablet.landscape',
  'gdGap.tablet.portrait',

  'gdGap.web',
  'gdGap.web.landscape',
  'gdGap.web.portrait',
];

const selector = `[gdGap.landscape], [gdGap.portrait],
[gdGap.handset], [gdGap.handset.landscape], [gdGap.handset.portrait],
[gdGap.tablet], [gdGap.tablet.landscape], [gdGap.tablet.portrait],
[gdGap.web], [gdGap.web.landscape], [gdGap.web.portrait]`;

@Directive({
  selector,
  inputs,
})
export class OrientationGridGapDirective extends GridGapDirective {
  protected override inputs = inputs;
}
