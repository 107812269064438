<div
  fxLayout
  safe-bottom
  safe-side
  class="footer-wrapper"
  [ngStyle.lt-sm]="{
    'height.px': 56,
    position: 'fixed',
    'box-shadow': '0 8px 20px 0 rgba(0, 0, 0, 0.2)'
  }"
  [ngStyle.gt-xs]="{ 'background-color': 'black' }"
>
  <div fxHide.gt-xs style="width: 100%" fxLayout fxLayoutAlign="center center">
    <button
      type="button"
      class="footer-menu"
      routerLink="/"
      routerLinkActive="on"
      [routerLinkActiveOptions]="activeOptions"
      fragment="home"
      mat-ripple
    >
      <svg>
        <use href="/assets/img/ic_home.svg#path"></use>
      </svg>
      <span>{{ 'home' | translate }}</span>
    </button>
    <button
      type="button"
      class="footer-menu"
      routerLink="/"
      routerLinkActive="on"
      [routerLinkActiveOptions]="activeOptions"
      fragment="menu"
      mat-ripple
    >
      <svg>
        <use href="/assets/img/ic_menu.svg#path"></use>
      </svg>
      <span>{{ 'menu' | translate }}</span>
    </button>
    <button
      type="button"
      class="footer-menu"
      routerLink="/"
      routerLinkActive="on"
      [routerLinkActiveOptions]="activeOptions"
      fragment="mail-contact"
      mat-ripple
    >
      <svg>
        <use href="/assets/img/ic_contact.svg#path"></use>
      </svg>
      <span>{{ 'contact' | translate }}</span>
    </button>
    <button
      type="button"
      class="footer-menu"
      routerLink="/"
      routerLinkActive="on"
      [routerLinkActiveOptions]="activeOptions"
      fragment="mrhst"
      mat-ripple
    >
      <svg>
        <use href="/assets/img/ic_intro.svg#path"></use>
      </svg>
      <span>{{ 'mrhstInfo' | translate }}</span>
    </button>
  </div>
  <div fxHide fxShow.gt-xs class="footer-desk">
    <div fxLayout fxLayoutGap="40px">
      <a href="https://www.instagram.com/pusantei0208" target="_blank">
        <img class="footer-desk-sns" src="/assets/img/ic_insta.svg" />
      </a>
      <a>
        <img class="footer-desk-sns" src="/assets/img/ic_fb.svg" />
      </a>
    </div>
    <div class="footer-desk-content">
      <span class="company-name" ngClass.lt-sm="mobile">
        {{ 'busantei' | translate }}
      </span>
      <!-- <a class="company-phone" ngClass.lt-sm="mobile" href="tel:0977-21-7123"
        >TEL 0977-21-7123</a
        > -->
      <span class="trademark" ngClass.lt-sm="mobile">
        {{ 'trademark' | translate }}
      </span>
      <span class="copyright"
        >Copyright ©<span style="text-decoration: underline">Paywith</span> All
        rights reserved.</span
      >
    </div>
  </div>
</div>
<div
  class="footer-spacer"
  fxHide.gt-xs
  [ngStyle.lt-sm]="{
    'height.px': 56
  }"
></div>
