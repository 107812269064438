import { Component, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

@Component({
  selector: 'app-busantei-footer',
  templateUrl: './busantei-footer.component.html',
  styleUrls: ['./busantei-footer.component.scss'],
})
export class BusanteiFooterComponent implements OnInit {
  activeOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'exact',
    fragment: 'exact',
  };

  constructor() {}

  ngOnInit(): void {}
}
