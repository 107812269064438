<div
  class="header-wrapper"
  [ngStyle.lt-sm]="{ 'height.px': 56 }"
  [ngStyle.gt-xs]="{ 'height.px': 212 }"
>
  <div fxLayout="column">
    <div class="header-upper" fxFlex="56px" fxFlex.gt-xs="112px">
      <div
        fxLayoutAlign="center center"
        class="header-left"
        #left
        [fxFlex]="titlePaddingLevel * titlePaddingUnit + 'px'"
      >
        <ng-content select="[left]"></ng-content>
        <mat-icon (click)="onBackClick()" *ngIf="hasBack"
          >arrow_back_ios</mat-icon
        >
      </div>
      <div fxFlex="grow" fxLayoutAlign="center center" class="header-center">
        <ng-content></ng-content>
      </div>
      <div
        fxLayoutAlign="center center"
        class="header-right"
        #right
        [fxFlex]="titlePaddingLevel * titlePaddingUnit + 'px'"
      >
        <ng-content select="[right]"></ng-content>
      </div>
    </div>
    <div
      [ngStyle]="{ 'border-top': '1px solid #cfcfcf' }"
      fxFlex.gt-xs="100px"
      fxHide.lt-sm
    >
      <div
        fxFlex="grow"
        fxLayoutAlign="center center"
        fxLayoutGap="56px"
        class="header-menu"
      >
        <a
          routerLink="/"
          routerLinkActive="on"
          [routerLinkActiveOptions]="activeOptions"
          fragment="home"
          >{{ 'home' | translate }}</a
        >
        <a
          routerLink="/"
          routerLinkActive="on"
          [routerLinkActiveOptions]="activeOptions"
          fragment="menu"
          >{{ 'menu' | translate }}</a
        >
        <a
          routerLink="/"
          routerLinkActive="on"
          [routerLinkActiveOptions]="activeOptions"
          fragment="mail-contact"
          >{{ 'contact' | translate }}</a
        >
        <a
          routerLink="/"
          routerLinkActive="on"
          [routerLinkActiveOptions]="activeOptions"
          fragment="mrhst"
          >{{ 'mrhstInfo' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
<div
  class="header-spacer"
  [ngStyle.lt-sm]="{ 'height.px': 56 }"
  [ngStyle.gt-xs]="{ 'height.px': 212 }"
></div>
